export enum EventTag {
  // Category tags
  Party = 'party',
  FashionWeek = 'fashion week',
  Art = 'art',
  DJ = 'dj set',
  Networking = 'networking',
  Outdoor = 'outdoor',
  LiveShows = 'live shows',
  Sports = 'sports',
  Game = 'game',
  WebSummit = 'web summit',
}
